body{
    background-color: #e9ecee;
}
.container{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width:1700px;
    height:650px;
    padding:50px;
    background-color: #dde3e9;
    box-shadow: 0 30px 50px #b6b6b6;
}
#slide{
    width:max-content;
    margin-top:50px;
}
.item{
    width:250px;
    height:170px;
    background-position: 50% 50%;
    display: inline-block;
    transition: 0.5s;
    background-size: cover;
    position: absolute;
    z-index: 1;
    top:50%;
    transform: translate(0,-50%);
    border-radius: 20px;
    box-shadow:  0 30px 50px #505050;
}

.item:nth-child(1){
    left:30%;
}
.item:nth-child(2){
    left:calc(30% + 270px);
}
.item:nth-child(3){
    left:calc(30% + 540px);
}
.item:nth-child(4){
    left:calc(10% + 600px);
    transform: translate(0,60%);
    bottom:50%;
}
.item:nth-child(5){
    left:calc(10% + 900px);
    transform: translate(0,60%);
    bottom:50%;


}

.item .content{
    position: absolute;
    left:100px;
    width:300px;
    text-align: left;
    color:#181212;
    transform: translate(0,-50%);
    font-family: system-ui; 
}
.item:nth-child(2) .content{
    display: block;
    z-index: 11111;
}
.item .name{
    font-weight: bold;
    opacity: 0;
    animation:showcontent 1s ease-in-out 1 forwards
}
.item .des{
    margin:20px ; 
    opacity: 0;
    animation:showcontent 1s ease-in-out 0.3s 1 forwards
}
.item button{
    padding:10px 20px;
    border:none;
    opacity: 0;
    animation:showcontent 1s ease-in-out 0.6s 1 forwards
}
@keyframes showcontent{
    from{
        opacity: 0;
        transform: translate(0,100px);
        filter:blur(33px);
    }to{
        opacity: 1;
        transform: translate(0,0);
        filter:blur(0);
    }
}
.buttons{
    position: absolute;
    bottom:30px;
    z-index: 222222;
    text-align: center;
    width:100%;
}
.buttons button{
    width:50px;
    height:50px;
    border-radius: 50%;
    border:1px solid #555;
    transition: 0.5s;
}.buttons button:hover{
    background-color: #bac383;
}