@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* src/styles/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.2s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
}

.circle {
  @apply w-8 h-8 my-12 mx-1 bg-gray-700 rounded-full;
}
body{
  padding: 0 !important;
  font-family: 'Nunito', sans-serif;
    
}





html{
  overscroll-behavior: none;
}
