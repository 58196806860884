
.bg-image-login-form {
  background-image: url('/public/Register-Background.png');
  background-size: cover;
  background-position: center;
}
.bg-color-login-form{
  background: linear-gradient(115deg, #9F7AEA, #FEE2FE);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color-primary);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}
.fc-event .fc-event-title {
  color: rgb(254, 80, 49) !important;  
  font-size: 1.5rem !important; /* Adjust size as needed */
  font-weight: bold !important;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


html{
  background-color: #0F172A;

}

/* Basic Styles */
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  /* //page transition  */
  transition-property: opacity,transform,filter,background;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}

/* page transition */
.page-transition{
  opacity: 0;
  background:#312e81;
  transform: translateY(40px);
  filter: blur(50%);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f0f0f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header nav a {
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.header nav a:hover {
  background-color: #ddd;
}

.hero {
  /* background-image: url("[image url]");  Replace with the actual image URL */
  background-size: cover;
  background-position: center;
  padding: 10rem 0;
  color: #fff;
  text-align: center;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.hero button {
  background-color: #333;
  color: #fff;
  /* padding: 1rem 2rem; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hero button:hover {
  background-color: #222;
}

/* Calendar Modal Styles */
.react-calendar {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in-animation {
  animation: fadeIn 0.5s ease-in-out;
}

/* Responsive Styles */
@media (max-width: 768px) {
  html{
    background-color: #0F172A;
  }
  
  
  .header nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .header nav.active {
    display: block;
  }

  .header nav a {
    padding: 1rem;
  }

  .hero {
    padding: 5rem 0;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }
}
