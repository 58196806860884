.upload-button {
    display: flex;
    cursor: pointer;
     justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    transition: opacity 0.2s;
  }
  
  .upload-button:hover {
    opacity: 0.9;
  }
  
  .upload-text {
    color: white;
    padding-left: 0.5rem;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 9999;
  }
  
  .modal-content {
    position: relative;
    background-color: #1a1a1a;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 400px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .modal-content h2 {
    margin-bottom: 1.5rem;
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .modal-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .modal-button:hover {
    background-color: #1d4ed8;
    transform: translateY(-1px);
  }
  
  .hidden-file-input {
    display: none;
  }
  
  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }
  
  .close-button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .preview-container {
    margin-top: 1rem;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .preview-buttons {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .preview-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .preview-button.delete {
    background-color: #ef4444;
    color: white;
  }
  
  .preview-button.delete:hover {
    background-color: #dc2626;
  }
  
  .preview-button.upload {
    background-color: #10b981;
    color: white;
  }
  
  .preview-button.upload:hover {
    background-color: #059669;
  }
  
  .preview-button svg {
    margin-right: 0.5rem;
  }
  
  @media (max-width: 640px) {
    .upload-button {
      padding-right: 100px;
    }
    
    .modal-content {
      width: 95%;
      margin: 0 1rem;
      padding: 1.5rem;
    }
  }
  
  